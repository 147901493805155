import {
    Button,
    Checkbox,
    FormControl,
    Input,
    Message,
    Spacer,
    Stack,
    Text,
    useCheckboxState,
} from '@kolonialno/lama';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useCSRFToken } from '../../../utils/csrf';
import { useFormErrors } from '../../../utils/viewArgs';
import Container from '../../components/Container';
import { useButtonLoading } from '../../utils/button-state';

export function SetPassword() {
    const { disabled, loadingState, setLoading } = useButtonLoading();
    const [searchParams] = useSearchParams();
    const csrf = useCSRFToken();
    const formErrors = useFormErrors();

    const hasErrors = Object.keys(formErrors).length > 0;

    const [password, setPassword] = useState<string>('');
    const passwordRef = useRef<HTMLInputElement>(null);
    const checkboxState = useCheckboxState();

    const id = searchParams.get('id');
    const token = searchParams.get('token');
    const newUser = searchParams.get('new-user');

    return (
        <Container title="Login portal">
            {hasErrors && (
                <Message variant="danger">
                    Could not create new password
                </Message>
            )}
            <Stack>
                <Text
                    variant="subtitle1"
                    className="white-text"
                    alignment="center"
                >
                    Create new password
                </Text>
                <Text className="white-text" alignment="center">
                    Please make sure your password includes at least 8
                    characters, 1 uppercase letter, 1 lowercase letter, and 1
                    number.
                </Text>

                <form
                    method="post"
                    onSubmit={() => {
                        setLoading();
                    }}
                >
                    <input
                        type="hidden"
                        name="csrfmiddlewaretoken"
                        value={csrf}
                    />
                    <input type="hidden" name="id" value={id || undefined} />
                    <input
                        type="hidden"
                        name="token"
                        value={token || undefined}
                    />
                    <input
                        type="hidden"
                        name="new_user"
                        value={newUser || undefined}
                    />

                    <Stack spacing="s">
                        <Stack direction="horizontal" alignItems="flex-start">
                            <FormControl
                                name="new_password"
                                label="New password"
                                className="form-control"
                            >
                                <Input
                                    ref={passwordRef}
                                    onChange={({ target }) =>
                                        setPassword(target.value)
                                    }
                                    value={password}
                                    type={
                                        checkboxState.checked
                                            ? 'text'
                                            : 'password'
                                    }
                                    required
                                    autoCapitalize="none"
                                    autoComplete="new-password"
                                />
                            </FormControl>
                        </Stack>

                        <Checkbox {...checkboxState} className="white-text">
                            Show password
                        </Checkbox>

                        <Spacer spacing="xs" />

                        <Button
                            type="submit"
                            variant="primary"
                            size="m"
                            disabled={disabled}
                            loadingState={loadingState}
                        >
                            Create password
                        </Button>
                    </Stack>
                </form>
            </Stack>
        </Container>
    );
}
