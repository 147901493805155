import './styles.scss';

import { ApolloProvider } from '@apollo/client';
import { Button, Link, Message, Stack } from '@kolonialno/lama';
import { useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import getClient from '../utils/apolloClient';
import Container from './components/Container';
import Routes from './routing';
import {
    AuthenticationContext,
    useAuthenticationContext,
} from './utils/context';

type UserAuthenticatedProps = {
    context: AuthenticationContext;
};
function UserAuthenticated({ context }: UserAuthenticatedProps) {
    const title = context.redirect
        ? 'Application access denied'
        : 'You are already authenticated';

    return (
        <Container title={title}>
            {context.redirect && (
                <Message variant="danger">
                    You don&apos;t have access to this application.
                </Message>
            )}

            <Stack>
                <Button variant="primary" size="m" as="a" href="/">
                    Open application portal
                </Button>
            </Stack>

            <Link
                className="purple-text"
                as="a"
                href={`${context.logoutUrl}?rd=${context.redirect}`}
            >
                Log out
            </Link>
        </Container>
    );
}

function InnerApp(props: { children: React.ReactNode }) {
    const context = useAuthenticationContext();

    if (context.authenticated) {
        return <UserAuthenticated context={context} />;
    }

    return <div>{props.children}</div>;
}

function App() {
    const client = useMemo(() => getClient(), []);

    return (
        <ApolloProvider client={client}>
            <Router>
                <InnerApp>
                    <Routes />
                </InnerApp>
            </Router>
        </ApolloProvider>
    );
}

export default App;
